import { Node, mergeAttributes } from "@tiptap/core"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    smoothLink: {
      setSmoothLink: (href: string, title: string) => ReturnType
    }
  }
}

export const SmoothLink = Node.create({
  name: "smoothLink",

  inline: true,

  group: "inline",

  draggable: true,

  content: "text*", // Allow text content inside the node

  addAttributes() {
    return {
      href: {
        default: null
      },
      title: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="smoothLink"]',
        getAttrs: (element) => {
          const el = element as HTMLElement
          return {
            href: el.getAttribute("href"),
            title: el.getAttribute("title")
          }
        }
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        "data-type": "smoothLink"
      }),
      0 // 0 indicates it allows content
    ]
  },

  addNodeView() {
    return ({ node }) => {
      const dom = document.createElement("span")
      dom.classList.add(
        "smooth-link",
        "underline",
        "font-bold",
        "cursor-pointer"
      )
      dom.innerText = node.attrs.title || node.attrs.href || "Link"

      dom.addEventListener("click", (event) => {
        event.preventDefault()
        const { href } = node.attrs
        if (href) {
          history.pushState(null, "", href)
          window.dispatchEvent(
            new CustomEvent("smoothlink-click", { detail: href })
          )
        }
      })

      return {
        dom,
        update: (updatedNode) => {
          if (updatedNode.type.name !== "smoothLink") {
            return false
          }
          dom.innerText =
            updatedNode.attrs.title || updatedNode.attrs.href || "Link"
          return true
        }
      }
    }
  },

  addCommands() {
    return {
      setSmoothLink: (href: string, title: string) => ({ chain }) => {
        return chain()
          .focus()
          .insertContent({
            type: this.name,
            attrs: { href, title }
          })
          .run()
      }
    }
  }
})
