import { extractAllImagesFromDocuments } from "../../documents"
import { DocumentResponseType } from "../../../api/responseBuilders/document"
import { generateHTML } from "@tiptap/html"
import { NodeHtmlMarkdown } from "node-html-markdown"
import superjson from "superjson"
import { defaultExtensions } from "../../../components/editor/extensions"

export const convertAllDocumentsToMarkdown = async ({
  allDocuments
}: { allDocuments: DocumentResponseType[] }) => {
  const images = await extractAllImagesFromDocuments(allDocuments)

  //@ts-ignore
  const convertToMarkdown = (document: DocumentResponseType) => {
    const html = generateHTML(
      JSON.parse(document.specifications!),
      defaultExtensions
    )
    const markdown = NodeHtmlMarkdown.translate(html)

    // Process children recursively
    // @ts-ignore
    const children = document.children
      ? document.children.map(convertToMarkdown)
      : []

    return {
      documentName: document.name,
      specifications: markdown,
      children: children
    }
  }

  const allDocumentsConverted = allDocuments.map(convertToMarkdown)

  return {
    documents: superjson.stringify(allDocumentsConverted),
    images
  }
}
