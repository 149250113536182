import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from "react"

interface SubscriptionContextProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const SubscriptionContext = createContext<SubscriptionContextProps | null>(null)

function SubscriptionProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <SubscriptionContext.Provider
      value={{
        open,
        setOpen
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext)
  if (!context) {
    throw new Error("useSubscription must be used within a HeaderProvider")
  }
  return context
}

export { SubscriptionContext, SubscriptionProvider }
