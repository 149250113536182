import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import axios from "src/utils/axios"
import {
  SearchProjectsOrder,
  SearchProjectsQueryType
} from "../../validation/project/search"
import {
  CreateProjectParameterType,
  UpdateProjectParameterType
} from "../applicationServices/project"
import {
  ProjectResponseType,
  ProjectsResponseType
} from "../responseBuilders/project"

export class ProjectApi {
  public static async getProject(projectId: string) {
    const res = await axios.get<ProjectResponseType>(
      `/api/v1.0/projects/${projectId}`
    )
    return res.data
  }

  public static async searchProjects(param: SearchProjectsQueryType) {
    const res = await axios.get<ProjectsResponseType>(
      "/api/v1.0/projects/search",
      {
        params: param
      }
    )
    return res
  }

  public static async createProject(
    param: Omit<CreateProjectParameterType, "userId">
  ) {
    const res = await axios.post<ProjectResponseType>(
      "/api/v1.0/projects",
      param
    )
    return res.data
  }

  public static async updateProject({
    projectId,
    params
  }: Omit<UpdateProjectParameterType, "userId">) {
    const res = await axios.patch<ProjectResponseType>(
      `/api/v1.0/projects/${projectId}`,
      params
    )
    return res.data
  }

  public static async deleteProject({ projectId }: { projectId: string }) {
    const res = await axios.delete<ProjectResponseType>(
      `/api/v1.0/projects/${projectId}`
    )
    return res.data
  }
}

export const useCreateProject = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (param: Omit<CreateProjectParameterType, "userId">) =>
      ProjectApi.createProject(param),
    {
      onSuccess: () => queryClient.invalidateQueries(["projects", "search"]),
      onError: (error: AxiosError) => {
        const serverErrors = error.response?.data?.errors
        if (serverErrors && serverErrors.length > 0) {
          const errorCode = serverErrors[0].code
          throw new Error(errorCode)
        }
      }
    }
  )
}

export const useGetProject = ({ ProjectId }: { ProjectId?: string }) =>
  useQuery({
    queryKey: ["projects", "get", ProjectId],
    queryFn: () => {
      if (ProjectId) return ProjectApi.getProject(ProjectId)
    },
    enabled: !!ProjectId,
    staleTime: Infinity,
    cacheTime: Infinity
  })

type InvalidateQuery = {
  invalidateProjectSearch?: boolean
  invalidateBookGet?: boolean
  invalidateGoalGet?: boolean
  invalidateUserTagSearch?: boolean
  invalidateLearningPlanSearch?: boolean
  invalidateProjectPerStatus?: boolean
}

export const useUpdateProject = (params: InvalidateQuery) => {
  const { invalidateProjectSearch = false } = params
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ projectId, params }: UpdateProjectParameterType) =>
      ProjectApi.updateProject({
        projectId,
        params
      }),
    onSuccess: () => {
      if (invalidateProjectSearch) {
        queryClient.invalidateQueries(["projects", "search"])
      }
    },
    onError: (_err, _newTodo, context) => {
      // queryClient.setQueryData(
      //   ["books", "get", context?.updateParameter.bookId],
      //   context?.previousBook
      // )
    }
  })
}

// // ENHANCE: reuse argument type
export function useSearchProjects(param: {
  searchText?: string
  offset?: number
  limit?: number
  order?: SearchProjectsOrder
  sort?: "asc" | "desc"
  teamId: string
  status?: number
  doneDateRangeStart?: Date
  doneDateRangeEnd?: Date
  userTagIds?: string[]
  includeNoTags?: boolean
  enabled?: boolean
  staleTime?: number
  cacheTime?: number
}) {
  const {
    searchText = "",
    offset = 0,
    limit,
    order = "order",
    sort = "asc",
    teamId,
    enabled = true,
    staleTime = Infinity,
    cacheTime = Infinity
  } = param
  return useQuery({
    queryKey: ["projects", "search", param],
    queryFn: ({ pageParam = offset }) =>
      ProjectApi.searchProjects({
        order: order,
        sort: sort,
        name: searchText,
        offset: pageParam,
        limit: limit,
        teamId: teamId
      }),
    staleTime,
    cacheTime,
    enabled
  })
}

export const useDeleteProject = (params: InvalidateQuery) => {
  const { invalidateProjectSearch = false } = params
  const queryClient = useQueryClient()
  return useMutation(
    (ProjectId: string) => ProjectApi.deleteProject({ projectId: ProjectId }),
    {
      onSuccess: async () => {
        if (invalidateProjectSearch) {
          await queryClient.invalidateQueries(["projects", "search"])
        }
      }
    }
  )
}
