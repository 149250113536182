// import "@/styles/tailwind.css"
// import "@/styles/prosemirror.css"
import "@/styles/index.css"
// import "@/styles/globals.css"
// import "@/styles/editor.css"
// import "./index.scss"
import "allotment/dist/style.css"
// import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.css"
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { appWithTranslation } from "next-i18next"
import Head from "next/head"
import { useRouter } from "next/router"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ToastContainer } from "react-toastify"
import { AuthProvider } from "../contexts/SupabaseAuthContext"
import * as ga from "../utils/gtag"
import { EditorProvider } from "../contexts/DocumentContext"
import { TeamProvider } from "../contexts/TeamContext"
import { HeaderProvider } from "../contexts/HeaderContext"
import dynamic from "next/dynamic"
import { SubscriptionProvider } from "../contexts/SubscriptionContext"

// const AuthProvider = dynamic(() => import("../contexts/FirebaseAuthContext"), {
//   ssr: true
// })
// const ReadingSessionProvider = dynamic(
//   () => import("../contexts/ReadingSessionContext"),
//   { ssr: true }
// )

// const DocLayout = dynamic(() => import("../layouts/DocLayout"), { ssr: true })

const DynamicProceedSubscriptionModal = dynamic(
  () => import("../components/billing/ProceedSubscriptionModal"),
  { ssr: false }
)

function usePrevious(value: any) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// @ts-ignore
function App({ Component, pageProps, router }) {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: "offlineFirst",
            refetchOnWindowFocus: false
          },
          mutations: {
            networkMode: "offlineFirst"
          }
        }
      }),
    []
  )
  const nextRouter = useRouter()
  const previousPathname = usePrevious(router.pathname)

  const getLayout = Component.getLayout ?? ((page: ReactNode) => page)

  // Let's create a state to keep track of whether we're in a browser environment.
  // Initially, we assume that we're on the server.
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    // As soon as useEffect runs, we're in the browser.
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (isClient) {
      if (nextRouter.pathname === "/") {
        // document.body.classList.remove("dark:bg-slate-900")
      } else {
        // document.body.classList.add("dark:bg-slate-900")
      }
    }
  }, [nextRouter.pathname, isClient])

  useEffect(() => {
    if (isClient) {
      const handleRouteChange = (url: string) => {
        ga.pageview(url)
      }

      nextRouter.events.on("routeChangeComplete", handleRouteChange)
      return () => {
        nextRouter.events.off("routeChangeComplete", handleRouteChange)
      }
    }
  }, [nextRouter.events, isClient])

  const component = getLayout(
    <Component {...pageProps} previousPathname={previousPathname} />
  )

  // Here we need to check if we are on the client side or server side
  // if (isClient && nextRouter.pathname.startsWith("/docs")) {
  //   component = (
  //     // <DocLayout {...pageProps}>
  //       <Component {...pageProps} />
  //     // </DocLayout>
  //   )
  // }

  return (
    <div>
      <Head>
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
      </Head>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AuthProvider>
            <SubscriptionProvider>
              <DynamicProceedSubscriptionModal />
              <TeamProvider>
                <HeaderProvider>
                  <EditorProvider>
                    {isClient && (
                      <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-left"
                        panelPosition="left"
                      />
                    )}
                    {component}
                  </EditorProvider>
                </HeaderProvider>
              </TeamProvider>
            </SubscriptionProvider>
          </AuthProvider>
        </Hydrate>
      </QueryClientProvider>
    </div>
  )
}

export default appWithTranslation(App)
