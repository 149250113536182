import React, { createContext, useContext, useEffect, useState } from "react"
import { DocumentResponseType } from "../api/responseBuilders/document"

import { useSearchDocuments } from "../api/queries/document"
import { convertAllDocumentsToMarkdown } from "../utils/ai/prompt"
import { useTeamContext } from "./TeamContext"
import { GenieSpecVersion } from "../api/domainModels/versioning"

interface DocumentContextType {
  searchDocumentResults: DocumentResponseType[]
  setSearchDocumentResults: React.Dispatch<
    React.SetStateAction<DocumentResponseType[]>
  >
  selectedDocument: DocumentResponseType | null
  setSelectedDocument: React.Dispatch<
    React.SetStateAction<DocumentResponseType | null>
  >
  parentDocumentId: string | undefined
  setParentDocumentId: React.Dispatch<React.SetStateAction<string | undefined>>
  documentTitles: Record<string, string>
  setDocumentTitles: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  createPrompt: () => Promise<{
    documentPrompt: string
    imageUrls: string[]
  }>
  createAllDocumentPrompt: () => Promise<{
    documentPrompt: string
    imageUrls: string[]
  }>
  createContinueWritingPrompt: (prevText: { prevText: string }) => Promise<{
    documentPrompt: string
    systemInstruction: string
    images: {
      src: string
      base64Image: string
    }[]
  }>
  getDocumentStructures: () => Promise<{
    documents: string
    imageUrls: string[]
  }>
  allMarkdownDocuments: string | null
  setAllMarkdownDocuments: React.Dispatch<React.SetStateAction<string | null>>
  currentVersion: GenieSpecVersion | undefined
  setCurrentVersion: React.Dispatch<
    React.SetStateAction<GenieSpecVersion | undefined>
  >
  latestCommitId: string | null
  setLatestCommitId: React.Dispatch<React.SetStateAction<string | null>>
}

const DocumentContext = createContext<DocumentContextType | undefined>(
  undefined
)

export const EditorProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [searchDocumentResults, setSearchDocumentResults] = useState<
    DocumentResponseType[]
  >([])
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentResponseType | null>(null)
  const [parentDocumentId, setParentDocumentId] = useState<string | undefined>()
  const { currentSelectedProject } = useTeamContext()
  const [documentTitles, setDocumentTitles] = useState<Record<string, string>>(
    {}
  )
  const [allMarkdownDocuments, setAllMarkdownDocuments] = useState<
    string | null
  >(null)
  const { data } = useSearchDocuments({
    projectId: currentSelectedProject?.id,
    enabled: !!currentSelectedProject?.id,
    versionId: currentSelectedProject?.currentVersion?.id,
    fields: ["id", "name", "specifications", "children"]
  })
  const [currentVersion, setCurrentVersion] = useState<GenieSpecVersion>()
  const [latestCommitId, setLatestCommitId] = useState<string | null>(null)

  // Function to flatten documents and build the titles map
  const flattenDocuments = (
    docs: DocumentResponseType[],
    titles: Record<string, string>
  ) => {
    docs.forEach((doc) => {
      titles[doc.id] = doc.name
      if (doc.children) {
        flattenDocuments(doc.children, titles) // Recursively add children
      }
    })
  }

  useEffect(() => {
    if (data?.data?.documents) {
      const titles: Record<string, string> = {}
      flattenDocuments(data.data.documents, titles)
      setDocumentTitles(titles)
    }
  }, [data])

  const createPrompt = async () => {
    if (!data?.data?.documents) throw new Error("No documents found")
    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })

    const documentPrompt = `
    Here are the folder structure and specifications of the documents. Use this as a reference and context.
    ${documents}

    You must FOCUS on the document ${selectedDocument!.name}.
    You should mainly review ${selectedDocument!.name} document and I'll ask you something about this doc.
    `

    return {
      documentPrompt,
      imageUrls: images.map((image) => image.src)
    }
  }

  const getDocumentStructures = async () => {
    if (!data?.data?.documents) throw new Error("No documents found")
    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })

    setAllMarkdownDocuments(documents)

    return {
      documents,
      imageUrls: images.map((image) => image.src)
    }
  }

  const createContinueWritingPrompt = async ({
    prevText
  }: { prevText: string }) => {
    if (!data?.data?.documents) throw new Error("No documents found")
    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })
    const systemInstruction = `
    You are a system specification document AI.

    I'll show you the structure of the documents and their specifications.
    The documents are structured hierarchically.
    This JSON is fetched by search document api. Specifications is stored as Prosemirror schema. JSON itself is not important, focus on the content, structure, images.

    ${documents}

    Follow the following rules:
    * DO NOT INCLUDE original document content, just only RETURN content you generate.
    * Return content format must be markdown.
    * Output language must be the same as the specifications. For example, if specifications are written in Japanese, the output must be in Japanese.
    * Documentation should use the same style and terminology as other documents.
    * Generate content as much as possible.
    `

    const documentPrompt = `
    Create a continuation of the document from the following text.
    document name: ${selectedDocument?.name}
    previous text: ${prevText}
    `

    return {
      documentPrompt,
      systemInstruction,
      images
    }
  }

  const createAllDocumentPrompt = async () => {
    if (!data?.data?.documents) throw new Error("No documents found")

    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })

    const documentPrompt = `
    You are a system specification document AI.
    The documents are structured hierarchically.

    I'll show you the structure of the documents and their specifications.
    This JSON is fetched by search document api. Specifications is stored as Prosemirror schema. JSON itself is not important, focus on the content, structure, images.

    ${documents}
    `

    return {
      documentPrompt,
      imageUrls: images.map((image) => image.src)
    }
  }

  return (
    <DocumentContext.Provider
      value={{
        searchDocumentResults,
        setSearchDocumentResults,
        selectedDocument,
        setSelectedDocument,
        parentDocumentId,
        setParentDocumentId,
        documentTitles,
        setDocumentTitles,
        createPrompt,
        createAllDocumentPrompt,
        createContinueWritingPrompt,
        getDocumentStructures,
        allMarkdownDocuments,
        setAllMarkdownDocuments,
        currentVersion,
        setCurrentVersion,
        latestCommitId,
        setLatestCommitId
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}

export const useDocumentContext = (): DocumentContextType => {
  const context = useContext(DocumentContext)
  if (!context) {
    throw new Error("useDocumentContext must be used within an EditorProvider")
  }
  return context
}
