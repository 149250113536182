import { Session, User } from "@supabase/supabase-js"
import { createContext, ReactNode, useEffect, useState } from "react"
import { useGetUser } from "../api/queries/user"
import { SupabaseAuthContextType } from "../types/auth"
import { createClient } from "../utils/supabase/component"
import { v4 as uuidv4 } from "uuid"

const AuthContext = createContext<SupabaseAuthContextType | null>(null)
const supabase = createClient()

function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [session, setSession] = useState<Session | null>(null)
  const [loading, setLoading] = useState(true)
  const { data, refetch: refetchUserInfo } = useGetUser(user?.id)

  useEffect(() => {
    const setData = async () => {
      const {
        data: { session },
        error
      } = await supabase.auth.getSession()
      if (error) throw error
      setSession(session)
      setUser(session?.user ?? null)
      setLoading(false)
    }

    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session)
        setUser(session?.user ?? null)
        setLoading(false)
      }
    )

    setData()

    return () => {
      listener?.subscription.unsubscribe()
    }
  }, [])

  const signInWithEmail = async (email: string, password: string) => {
    setLoading(true)
    const res = await supabase.auth.signInWithPassword({
      email,
      password
    })

    return res
  }

  const signUpWithEmail = async (
    email: string,
    password: string,
    name: string
  ) => {
    setLoading(true)
    const default_team_name = name + "-projects"
    const default_team_id = uuidv4()
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          name,
          default_team_id,
          default_team_name
        }
      }
    })
    setUser(data.user)
    setLoading(false)
    return { user: data.user, error }
  }

  const signOut = async () => {
    await supabase.auth.signOut()
    setUser(null)
  }

  const resetPassword = async (email: string) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email)
    return { data, error }
  }

  return (
    <AuthContext.Provider
      value={{
        // @ts-ignore
        user: { ...user, ...{ name: data?.name ?? null } },
        session,
        signInWithEmail,
        signUpWithEmail,
        signOut,
        resetPassword,
        loading,
        refetchUserInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
