import axios from "axios"
import { createClient } from "./supabase/component"
// import supabase from "./utils/supabase/"

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const { data, error } = await createClient().auth.getSession()
    const token = data.session?.access_token

    if (token && config.headers) {
      config.headers.Authorization = token
      // setCookie("token", token, { expires: 7 })
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axiosInstance
