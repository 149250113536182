import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import { v4 as uuid } from "uuid"
import { AiWriterView } from "./components/AiWriterView"
import { DocumentApi } from "../../../../api/queries/document"
import { getFollowingText, getPrevText } from "../../utils"
import MarkdownIt from "markdown-it"
import DOMPurify from "dompurify"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    aiWriter: {
      setAiWriter: () => ReturnType
    }
  }
}

const md = new MarkdownIt({
  breaks: true // Enable line breaks
})

export const AiWriter = Node.create({
  name: "aiWriter",

  group: "block",

  draggable: true,

  addOptions() {
    return {
      projectId: undefined,
      documentId: undefined,
      HTMLAttributes: {
        class: `node-${this.name}`
      }
    }
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => ({
          "data-id": attributes.id
        })
      },
      content: {
        default: "loading",
        parseHTML: (element) => element.getAttribute("data-content"),
        renderHTML: (attributes) => ({
          "data-content": attributes.content
        })
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addCommands() {
    return {
      setAiWriter: () => ({ chain, editor }) => {
        const id = uuid()
        const from = editor.state.selection.from

        // Insert the node with loading state
        chain()
          .focus()
          .insertContent({
            type: this.name,
            attrs: {
              id,
              content: "loading"
            }
          })
          .run()

        // Fetch AI suggestion
        const fetchAiContent = async () => {
          const previousText = getPrevText(editor, from)
          const followingText = getFollowingText(editor, from)
          const res = await DocumentApi.continueWritingWithAI({
            projectId: this.options.projectId,
            documentId: this.options.documentId,
            previousText,
            followingText
          })
          const stringRes = JSON.stringify(res)
          const processedRes = stringRes.slice(1, -1).replace(/\\n/g, "\n")
          const markdownToHtml = md.render(processedRes)
          const sanitizedHtml = DOMPurify.sanitize(markdownToHtml)

          // Find the position of the inserted node
          let nodePos = null
          // @ts-ignore
          editor.state.doc.descendants((node, pos) => {
            if (node.attrs.id === id) {
              nodePos = pos
              return false
            }
            return true
          })

          if (nodePos !== null) {
            // Update the node with the AI suggestion
            const transaction = editor.state.tr.setNodeMarkup(
              nodePos,
              undefined,
              {
                id,
                content: sanitizedHtml
              }
            )

            editor.view.dispatch(transaction)
          }
        }

        fetchAiContent().catch(console.error)

        return true
      }
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiWriterView)
  }
})

export default AiWriter
