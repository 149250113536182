import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "src/utils/axios"
import { UserResponseType } from "../responseBuilders/user"
import { UpdateUserParameterType } from "../applicationServices/user"
import { UpdatePaymentMethodParameterType } from "../applicationServices/subscription"

class UserApi {
  public static async get(id?: string) {
    const res = await axios.get<UserResponseType>(`/api/v1.0/users/${id}`)
    return res.data
  }
  public static async patch({
    id,
    param
  }: {
    id: string
    param: Omit<UpdateUserParameterType, "userId">
  }) {
    const res = await axios.patch<UserResponseType>(
      `/api/v1.0/users/${id}`,
      param
    )
    return res.data
  }
}

export const useGetUser = (id?: string) => {
  if (!id)
    return useQuery(["user", id], () => {
      return Promise.resolve(null)
    })
  // const router = useRouter()
  return useQuery(["user", id], () => UserApi.get(id), {
    enabled: !!id,
    staleTime: Infinity,
    cacheTime: Infinity
    // onError: () => {
    //   router.push("/auth/sign-in")
    // }
  })
}

type InvalidateQuery = {
  invalidateGetUser?: boolean
}

export const useUpdateUser = (params: InvalidateQuery) => {
  const { invalidateGetUser = false } = params
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      userId,
      param
    }: { userId: string; param: Omit<UpdateUserParameterType, "userId"> }) =>
      UserApi.patch({ id: userId, param }),
    onSettled: async (_1, _2, variable) => {
      if (invalidateGetUser) {
        await queryClient.invalidateQueries({
          queryKey: ["user", variable.userId]
        })
      }
    }
  })
}
