import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function sliceIntoChunks<T>(arr: T[], chunkSize: number) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export function moveElement<T>(array: T[], fromIndex: number, toIndex: number) {
  const element = array.splice(fromIndex, 1)[0]
  array.splice(toIndex, 0, element)

  return array
}

export function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length)
  return array[randomIndex]
}

export function toBoolean(value: any): boolean {
  if (typeof value === "string") {
    if (value.toLowerCase() === "true") {
      return true
    } else if (value.toLowerCase() === "false") {
      return false
    }
  }

  // Convert other values to boolean based on their truthy or falsy nature
  return Boolean(value)
}

export function randomElement<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)]
}
