import { Extension } from "@tiptap/core"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    linkToPage: {
      linkToPage: () => ReturnType
    }
  }
}

export const LinkToPage = Extension.create({
  name: "linkToPage",

  addCommands() {
    return {
      linkToPage: () => ({ editor }) => {
        // @ts-ignore
        editor.emit("openSelectLinkToPageModal")
        return true
      }
    }
  }
})
