import axios from "axios"
import { DocumentResponseType } from "../../api/responseBuilders/document"
import { parse } from "node-html-parser"

// Function to fetch image and convert to base64
export const fetchImageAsBase64 = async (url: string): Promise<string> => {
  // Fetch the image data as an ArrayBuffer
  const response = await fetch(url)

  // Check if the response is OK (status code 200-299)
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`)
  }

  // Extract the content type from the response headers
  const contentType = response.headers.get("content-type")

  // Convert the response data to an ArrayBuffer
  const arrayBuffer = await response.arrayBuffer()

  // Create a Buffer from the ArrayBuffer
  const buffer = Buffer.from(arrayBuffer)

  // Convert the Buffer to a Base64 string
  const base64String = buffer.toString("base64")

  // Return the Base64-encoded image with the data URI schema
  return `data:${contentType};base64,${base64String}`
}

// Function to parse HTML and extract images as base64
export const parseHTMLAndConvertImages = async (
  html: string
): Promise<string[]> => {
  const root = parse(html)
  const imgTags = root.querySelectorAll("img")
  const base64Images: string[] = await Promise.all(
    imgTags.map((img) => fetchImageAsBase64(img.getAttribute("src") || ""))
  )
  return base64Images
}

export const findParentDocuments = (
  documents: DocumentResponseType[],
  documentId: string
): DocumentResponseType[] => {
  const parentDocuments: DocumentResponseType[] = []

  const findDocumentAndParents = (
    docList: DocumentResponseType[],
    id: string
  ): DocumentResponseType | null => {
    for (const doc of docList) {
      if (doc.id === id) {
        return doc
      }
      if (doc.children && doc.children.length > 0) {
        const foundDoc = findDocumentAndParents(doc.children, id)
        if (foundDoc) {
          parentDocuments.push(doc)
          return foundDoc
        }
      }
    }
    return null
  }

  findDocumentAndParents(documents, documentId)
  return parentDocuments.reverse() // Reverse to have the root parent first
}

interface Node {
  type: string
  attrs?: {
    src?: string
    [key: string]: any
  }
  content?: Node[]
}

export const parseJSONAndExtractImages = (
  node: Node,
  images: string[] = []
): string[] => {
  if (node.type === "image" && node.attrs && node.attrs.src) {
    images.push(node.attrs.src)
  }

  if (node.content && node.content.length > 0) {
    node.content.forEach((child) => parseJSONAndExtractImages(child, images))
  }

  return images
}

export const extractAllImagesFromDocuments = async (
  documents: DocumentResponseType[]
): Promise<{ src: string; base64Image: string }[]> => {
  let allImages: string[] = []

  const traverseDocuments = (docs: DocumentResponseType[]) => {
    docs.forEach((document) => {
      const specificationContent = JSON.parse(document.specifications)
      allImages = parseJSONAndExtractImages(specificationContent, allImages)

      if (document.children && document.children.length > 0) {
        traverseDocuments(document.children)
      }
    })
  }

  traverseDocuments(documents)

  // Convert all images to base64
  const images = await Promise.all(
    allImages.map(async (i) => {
      return {
        src: i,
        base64Image: await fetchImageAsBase64(i)
      }
    })
  )

  return images
}

export const extractAllImageUrlsFromDocuments = async (
  documents: DocumentResponseType[]
): Promise<string[]> => {
  let allImageUrls: string[] = []

  const traverseDocuments = (docs: DocumentResponseType[]) => {
    docs.forEach((document) => {
      const specificationContent = JSON.parse(document.specifications)
      allImageUrls = parseJSONAndExtractImages(
        specificationContent,
        allImageUrls
      )

      if (document.children && document.children.length > 0) {
        traverseDocuments(document.children)
      }
    })
  }

  traverseDocuments(documents)

  return allImageUrls
}
