import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import Gapcursor from "@tiptap/extension-gapcursor"
import Highlight from "@tiptap/extension-highlight"
import HorizontalRule from "@tiptap/extension-horizontal-rule"
import TiptapImage from "@tiptap/extension-image"
import TiptapLink from "@tiptap/extension-link"
import Placeholder from "@tiptap/extension-placeholder"
import { TaskItem } from "@tiptap/extension-task-item"
import { TaskList } from "@tiptap/extension-task-list"
import TextStyle from "@tiptap/extension-text-style"
import StarterKit from "@tiptap/starter-kit"
import { cx } from "class-variance-authority"
import { common, createLowlight } from "lowlight"
import { Markdown } from "tiptap-markdown"
import { UploadImagesPlugin } from "../plugins"
import { AIHighlight } from "./ai-highlight"
import { AiWriter } from "./AiWriter"
import UpdatedImage from "./image/updated-image"
import { SlashCommand } from "./SlashCommand"
import { Table, TableCell, TableRow, TableHeader } from "./Table"
import { i18n } from "next-i18next"
import { LinkToPage } from "./LinkToPage/LinkToPage"
import { SmoothLink } from "./LinkToPage/SmoothLink"
import { Color } from "@tiptap/extension-color"
import Underline from "@tiptap/extension-underline"
import { FontSize } from "./FontSize"
import FontFamily from "@tiptap/extension-font-family"
export { FontFamily } from "@tiptap/extension-font-family"

const aiHighlight = AIHighlight
const tiptapLink = TiptapLink.configure({
  HTMLAttributes: {
    class: cx(
      "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer"
    ),
    target: "_self"
  }
})

const tiptapImage = TiptapImage.extend({
  addProseMirrorPlugins() {
    return [
      UploadImagesPlugin({
        imageClass: cx("opacity-40 rounded-lg border border-stone-200")
      })
    ]
  }
}).configure({
  allowBase64: true,
  HTMLAttributes: {
    class: cx("rounded-lg border border-muted")
  }
})

const updatedImage = UpdatedImage.configure({
  HTMLAttributes: {
    class: cx("rounded-lg border border-muted")
  }
})

const taskList = TaskList.configure({})
const taskItem = TaskItem.configure({
  nested: true
})

const horizontalRule = HorizontalRule.configure({
  HTMLAttributes: {
    class: cx("mt-4 mb-6 border-t border-muted-foreground")
  }
})

const starterKit = StarterKit.configure({
  horizontalRule: false,
  dropcursor: {
    color: "#DBEAFE",
    width: 4
  },
  gapcursor: false
})

const codeBlockLowlight = CodeBlockLowlight.configure({
  // configure lowlight: common /  all / use highlightJS in case there is a need to specify certain language grammars only
  // common: covers 37 language grammars which should be good enough in most cases
  lowlight: createLowlight(common)
})

// export default MarkdownPasteHandler
Markdown.configure({
  html: true, // Allow HTML input/output
  tightLists: true, // No <p> inside <li> in markdown output
  tightListClass: "tight", // Add class to <ul> allowing you to remove <p> margins when tight
  bulletListMarker: "-", // <li> prefix in markdown output
  linkify: true, // Create links from "https://..." text
  breaks: true, // New lines (\n) in markdown input are converted to <br>
  transformPastedText: true, // Allow to paste markdown text in the editor
  transformCopiedText: true // Copied text is transformed to markdown
})

export const defaultExtensions = [
  starterKit,
  FontFamily,
  FontSize,
  Underline,
  Color,
  SlashCommand,
  Placeholder.configure({
    includeChildren: true,
    placeholder: ({ node }) => {
      if (!i18n) return "'/' for commands"
      if (node.type.name === "heading") {
        // TODO: currently only heading level 1 is supported
        return `Heading ${node.attrs.level}`
      }
      return i18n?.t("'/' for commands")
    }
  }),
  tiptapLink,
  tiptapImage,
  updatedImage,
  Highlight.configure({
    multicolor: true
  }),
  TextStyle,
  taskList,
  taskItem,
  horizontalRule,
  aiHighlight,
  codeBlockLowlight,
  Gapcursor,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Markdown,
  AiWriter,
  LinkToPage,
  SmoothLink
]
